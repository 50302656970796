import React, { useMemo } from "react";
import { styled } from "goober";

import { readPath } from "@/__main__/get-data.mjs";
import { GAME_SYMBOL_LOL } from "@/game-lol/definition-symbol.mjs";
import ArrowDown from "@/inline-assets/stock-arrow-down.svg";
import ArrowUp from "@/inline-assets/stock-arrow-up.svg";
import { useOverlaySettings } from "@/library/actions.mjs";
import { useSimulationEngine } from "@/library/hooks.mjs";
import {
  OverlayContainer,
  OverlayContent,
  OverlayContentItems,
  OverlayHeader,
} from "@/library/SimulatedOverlays.style.jsx";
import useTFunction from "@/util/use-t-function.mjs";

const SIMULATED_JUNGLE_PATHING_CONFIG = {
  selectKey: ["junglePathingBenchmarks", "benchmark"],
  status: ["overlays:lol.junglePathing.simulated.status", "All Camps"],
  features: [
    {
      label: ["overlays:lol.junglePathing.simulated.firstClear", "1st Clear"],
      simulationSpec: {
        minutes: 2,
        seconds: 0,
        type: "timer",
        direction: "down",
      },
      circleColor: "var(--turq)",
      circleType: "fill",
      isCompleted: false,
      compare: 15,
    },
    {
      label: ["lol:jungleMonsters.redBuff", "Red Buff"],
      simulationSpec: {
        minutes: 2,
        seconds: 33,
        type: "timer",
        direction: "down",
      },
      circleColor: "var(--turq)",
      circleType: "fill",
      isCompleted: true,
      compare: 13,
    },
    {
      label: ["lol:jungleMonsters.krugs", "Krugs"],
      simulationSpec: {
        minutes: 1,
        seconds: 31,
        type: "timer",
        direction: "down",
      },
      circleColor: "var(--yellow)",
      circleType: "outline",
      isCompleted: false,
      compare: 8,
    },
    {
      label: ["lol:jungleMonsters.raptors", "Raptors"],
      simulationSpec: null,
      circleColor: "var(--shade1)",
      circleType: "outline",
      isCompleted: false,
      compare: null,
    },
  ],
} as const;

const SimulatedJunglePathingOverlay = () => {
  const settings = useOverlaySettings(GAME_SYMBOL_LOL);

  const { simulatedValues } = useSimulationEngine({
    simulationSchema: SIMULATED_JUNGLE_PATHING_CONFIG.features.reduce(
      (acc, feature, idx) => {
        acc[idx] = { ...feature.simulationSpec };
        return acc;
      },
      {},
    ),
    tickIntervalInMs: 1000,
    numberOfTicks: 10,
  });

  const data = useMemo(() => {
    if (!settings) return;
    if (!simulatedValues) return;
    const { selectKey, features, status } = SIMULATED_JUNGLE_PATHING_CONFIG;
    return {
      selectedValue: readPath(selectKey, settings),
      status: status,
      timers: features.map((feature, idx) => {
        return { ...feature, value: simulatedValues[idx] };
      }),
    };
  }, [settings, simulatedValues]);

  if (!data) return; // TEMP

  return (
    <OverlayContainer className="relative">
      <OverlayHeader />
      <OverlayContent className="w-full">
        <OverlayContentItems>
          <PathingTimer
            label={data.timers[0].label}
            value={data.timers[0].value}
            compare={data.timers[0].compare}
            isCompleted={data.timers[0].isCompleted}
          />
          {data.selectedValue === "detailed" &&
            data.timers
              .slice(1, data.timers.length)
              .map(
                (
                  {
                    label,
                    value,
                    compare,
                    circleColor,
                    circleType,
                    isCompleted,
                  },
                  index,
                ) => {
                  return (
                    <PathingTimer
                      key={index}
                      label={label}
                      value={value}
                      compare={compare}
                      isCompleted={isCompleted}
                      circleColor={circleColor}
                      circleType={circleType}
                    />
                  );
                },
              )}
        </OverlayContentItems>
      </OverlayContent>
    </OverlayContainer>
  );
};

export default SimulatedJunglePathingOverlay;

interface PathingTimerProps {
  label: Translation;
  value: number;
  compare: number;
  isCompleted: boolean;
  circleColor?: string;
  circleType?: string;
}

const PathingTimer = ({
  label,
  value,
  compare,
  isCompleted,
}: PathingTimerProps) => {
  const t = useTFunction();
  return (
    <TimerContainer>
      <div className="flex align-center gap-sp-1">
        <OverlayContentLabel
          className="type-caption"
          $isCompleted={isCompleted}
        >
          {t(label)}
        </OverlayContentLabel>
      </div>
      <div className="flex align-center gap-sp-2">
        <Timer value={value} compare={compare} isCompleted={isCompleted} />
      </div>
    </TimerContainer>
  );
};

interface TimerProps {
  value: number;
  compare: number;
  isCompleted: boolean;
}

const Timer = ({ value, compare, isCompleted }: TimerProps) => {
  const minutes = Math.floor(value / 60);
  const seconds = value % 60;
  const timerText = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;

  const fmtText = value ? timerText : "--:--";
  const fmtCompare = compare ? `${compare}s` : "--";
  const color = compare
    ? value > compare
      ? "var(--turq)"
      : "var(--red)"
    : "var(--shade1)";
  const Icon = compare ? (value > compare ? ArrowUp : ArrowDown) : null;

  return (
    <>
      <OverlayTimer
        className="type-body2-form--active"
        $isCompleted={isCompleted}
      >
        {fmtText}
      </OverlayTimer>
      <TargetVal style={{ color }}>
        {Icon && <Icon />}
        <span className="type-body2-form--active">{fmtCompare}</span>
      </TargetVal>
    </>
  );
};

const TimerContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--sp-3);

  transition: var(--transition);
  transform: translateY(0);

  .tag {
    width: var(--sp-14);
  }
`;

const OverlayContentLabel = styled("span")<{ $isCompleted: boolean }>`
  text-decoration: ${(props) => (props.$isCompleted ? "line-through" : "")};
  color: ${(props) =>
    props.$isCompleted ? "var(--shade0-50)" : "var(--shade0)"};
`;

const OverlayTimer = styled("span")<{ $isCompleted: boolean }>`
  color: ${(props) =>
    props.$isCompleted ? "var(--shade0-50)" : "var(--shade0)"};
`;

const TargetVal = styled("div")`
  display: flex;
  align-items: center;
  gap: var(--sp-0_5);

  svg {
    width: var(--sp-3);
  }
`;
